import React, { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";

import { searchApplication } from "../../services/utilities";
import { SearchContext } from "../searchContext";
import { SettingsContext } from "../settingsContext";
import { dataServices } from "../../services/dataServices";
import { NoOptionsMessage } from "../noOptionsMessage";
import FiltersModal from "./FilterModel";
import {
  IconBatch,
  IconStar,
  IconBell,
  IconExclamation,
  IconExrrorExclamatory,
} from "../icons";

/**
 * Component to render search result
 * @param {*} props
 * @returns render search result
 */
function SearchResult(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const stateCode = urlParams.get("state");
  const { t, i18n, sortOrderOptions, fetchProducts, setIsFilterApply, isFilterApply, setSelectedFiltersCount, selectedFiltersCount} = props;

  const searchContext = React.useContext(SearchContext);

  const appState = searchContext.appState;

  let details = navigator.userAgent;

  let regexp = /android|iphone/i;

  let isMobileDevice = regexp.test(details);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    const stored = sessionStorage.getItem("filtersSelected");
    let count =0;
    if(stored)
    {
        var filters= JSON.parse(stored);
        count=(filters.state?.length > 0 ? 1 : 0) +
        (filters.activeIngredient?.length > 0 ? 1 : 0) +
        (filters.category?.length > 0 ? 1 : 0) +
        (filters.company?.length > 0 ? 1 : 0) +
        (filters.marketLabel?.length > 0 ? 1 : 0)

    }
    setSelectedFiltersCount(count);
    
    if (count > 0) {
      setIsFilterApply(true);
    }
  }, []);
  
  //Get the settings from context
  const settings = React.useContext(SettingsContext);
  
    const start = appState.pageSize
    ? (appState.currentPage - 1) * appState.pageSize + 1
    : 1;
  const end = Math.min(
    appState.currentPage * appState.pageSize,
    appState.totalCount
  );

  var result =
    searchContext?.appState?.result?.length > 0
      ? searchContext?.appState?.result
      : [];

  /**
   * Event listner for sort drop down change
   * @param {*} order
   */
  const sortOrderChange = (order) => {
    fetchProducts(
      1,
      appState?.selectedFilters?.activeIngredient,
      appState?.selectedFilters?.state,
      order,
      appState?.selectedFilters?.company,
      appState?.selectedFilters?.category,
      appState?.selectedFilters?.marketLabel,

    );
  };

  /**
   * Event listner for active ingredient filter change
   * @param {*} selected active ingredient
   */
  const activeFilterChange = (selected) => {
    fetchProducts(1, selected, appState?.supportedState, appState?.sortOrder);
  };

  /**
   * Event listner for supported state filter change
   * @param {*} selected active ingredient
   */
  const stateFilterChange = (selected) => {
    fetchProducts(1, appState?.activeIngredient, selected, appState?.sortOrder);
  };

  /**
   * Update URL when filtered by active ingredients or sorted
   * @param {object} newState
   */

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // scroll to the result once its displayed on first load of page one time
    // added 10 milliseconds delay as on first load of page - scrolling is not happening exactly at result count element
    // could be because delay in loading filter element.
    setTimeout(() => {
      var offset = 0;
      var customcontainers =
        document.getElementsByClassName("custom-container");
      if (customcontainers.length) {
        offset =
          customcontainers.length === 2
            ? customcontainers[1].getBoundingClientRect().height
            : customcontainers[0].getBoundingClientRect().height;
      }

      if (!offset) {
        var mobileHeader = document.getElementsByClassName(
          "mobile-header-inner"
        );
        if (mobileHeader.length) {
          offset = mobileHeader[0]
            .querySelector(".container")
            .getBoundingClientRect().height;
        }
      }

      const section = document.querySelector(".result-count");
      if (section) {
        var brect = section.getBoundingClientRect();
        var scrollOption = {
          top: brect.top - offset + document.documentElement.scrollTop - 16,
          left: 0,
          behavior: "smooth",
        };
        window.scrollTo(scrollOption);
      }
    }, 10);
  }, []);

  //Passing search information to product details
  let searchData = {
    occupation: appState?.occupation?.label,
    occupation_en: appState?.occupation?.label_en,
    crop_id: appState?.crop?.value,
    crop: appState?.crop?.label,
    crop_en: appState?.crop?.label_en,
    pest_id: appState?.pest?.value,
    pest: appState?.pest?.label,
    pest_en: appState?.pest?.label_en,
    result_count: appState.result?.length ?? 0,
  };

  const emptyFilters = {
    state: [],
    category: [],
    activeIngredient: [],
    company: [],
    marketLabel: []
  };
  

  const fetchFilteredData = async (selectedFilters) => {
      try {
        const crop = appState.crop?.value ?? '';
        const pest = appState.pest?.value ?? '';
  
        const supportedIngredient = (selectedFilters.activeIngredient.length > 0) ? selectedFilters.activeIngredient.map(s => s.value) : [];
        const supportedStates = (selectedFilters.state.length > 0) ? selectedFilters.state.map(s => s.value) : [];
        const supportedCompany = (selectedFilters.company.length > 0) ? selectedFilters.company.map(s => s.value) : [];
        const supportedCategory = (selectedFilters.category.length > 0) ? selectedFilters.category.map(s => s.value) : [];
        const supportedMarketLabel = (selectedFilters.marketLabel.length > 0) ? selectedFilters.marketLabel.map(s => s.value) : []; // Corrected typo
  
        // Filter object
        const filterObject = {
          activeIngredients: supportedIngredient,
          states: supportedStates,
          company: supportedCompany,
          category: supportedCategory,
          marketLabel: supportedMarketLabel, // Corrected typo
        };
  
        const filterString = JSON.stringify(filterObject);
  
        const response = await dataServices.getProducts(
          appState.country,
          settings.getlng(), // Make sure this is a function call
          crop,
          pest,
          "",
          0,
          0,
          settings,
          filterString,
          "asc",
          "product"
        );
  
        if (response.status === 200) {
          const data = response.data.filters; // Fix: Extract JSON data
        return data;
        } else {
          console.log(["SearchForm:getProducts", response]);
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

  //Whether to show missing product notice for the country
  let showProductMissingNotice = false;
  if (settings.country_code_missing_product) {
    let countryCodes = settings.country_code_missing_product
      .toLowerCase()
      .split(",")
      .filter((x) => {
        return x.toLowerCase() === appState.country.toLowerCase();
      });

    showProductMissingNotice = countryCodes.length > 0;
  }

  const countrySelected = (code) => {
    var selected = appState.countries.filter((item) => item.value === code);

    if (selected && selected.length > 0) {
      return selected[0].label;
    }

    return null;
  };

  const queryParam = searchApplication.historyHelper.queryParam(
    useLocation().search
  );

  const cropSelected = () => {
    if (queryParam.crop) {
      var selected = appState.crops.filter(
        (item) => item.value === queryParam.crop
      );

      if (selected && selected.length > 0) {
        return selected[0].label;
      } else if (appState.crop) {
        return appState.crop.label;
      }
    }
    return null;
  };

  const pestSelected = () => {
    var selected = appState.pests.filter(
      (item) => item.value === queryParam.pest
    );

    if (selected && selected.length > 0) {
      return selected[0].label;
    } else if (appState.pest) {
      return appState.pest.label;
    }

    return null;
  };
  
  //When search not performed or search with 0 result
  if (result === null || result.length === 0) {
    return (
      <section data-testid="result-wrapper">
        <div className="container"></div>
      </section>
    );
  } else {
return (
      <section data-testid="result-wrapper">
        <div className={isMobileDevice ? "container s-r pt-2" : "container pt-4 s-r"}>
          <div className="row">
            <div className={isMobileDevice ? "col-sm-12 information-container ps-2 pe-2" : "col-sm-12 information-container"}>
              <IconBatch className="me-1 align-middle" />
              <div className="information">
                <h4
                  className={isMobileDevice ? "fw-normal lh-sm mb-0" : "fw-normal lh-sm"}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "ProductRegistration" +
                      searchApplication.removeWhiteSpace(
                        appState.countryName.split(" - ")[0]
                      )
                    ),
                  }}
                ></h4>
              </div>
            </div>
            {!settings.standalone && t("Signuptext") !== "Signuptext" && (
              <div className={isMobileDevice ? "col-sm-12 d-flex my-2" : "col-sm-12 d-flex my-3"}>
                <IconBell className="icon-size me-1 align-middle" />
                <div className="information">
                  <h4
                    className="fw-normal lh-sm m-0"
                    dangerouslySetInnerHTML={{
                      __html: t("Signuptext"),
                    }}
                  ></h4>
                </div>
              </div>
            )}
            <div className="col-sm-12">
              <h4 className={isMobileDevice ? "m-0" : "mt-3 mb-4"}>
                {RenderResultCount(settings, start, end, appState, t)}
                {
                  <span className="badge rounded-pill bg-cabi text-dark fs-6 me-1 mb-2 ms-1 mt-2">
                    {countrySelected(appState.country)}
                  </span>
                }
                {cropSelected() ? (
                  <span className="badge rounded-pill fs-6 bg-cabi text-dark me-1">
                    {cropSelected()}
                  </span>
                ) : null}
                {pestSelected() ? (
                  <span className="badge rounded-pill fs-6 bg-cabi text-dark ">
                    {pestSelected()}
                  </span>
                ) : null}
              </h4>
            </div>
          </div>
          <div className="row s-r">
            {showProductMissingNotice ? (
              <div className="col-sm-12 pb-4 d-flex align-items-start">
                <div className="me-1">
                  <IconExclamation width="20px" />
                </div>
                <div
                  className="fs-5 fw-bold pt-1"
                  dangerouslySetInnerHTML={{ __html: t("ProductMissingLabel") }}
                ></div>
              </div>
            ) : null}
            <div className="col-sm-12 s-f">
              <form>
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-12 d-flex justify-content-end align-items-center gap-3">
                  <div className={`control-container ${window.innerWidth < 768 ? 'mt-2' : 'mt-4'}`}>
                      <button className="btn btn-outline-dark d-flex align-items-center gap-2"
                        type="button" onClick={() => {if (selectedFiltersCount<= 0){fetchFilteredData(emptyFilters);} setIsFilterModalOpen(true);}}
                        >
                          
                        <Trans>FilterBy</Trans>
                         {selectedFiltersCount > 0 ? (
                          <span
                            className="badge"
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "50%",
                              padding: "5px 10px",
                              fontSize: "14px"
                            }}
                          >
                            {selectedFiltersCount}
                          </span>
                        ) : (
                          <svg width="16" height="16" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
                          </svg>
                        )}
                      </button>
                      {isFilterModalOpen && (
                        <FiltersModal
                          isOpen={isFilterModalOpen}
                          setIsOpen={setIsFilterModalOpen}
                          countryName={appState.countryName}
                          activeIngredients={searchContext.appState.filters.activeIngredients}
                          states={searchContext.appState.filters.states}
                          categories={searchContext.appState.filters.categories}
                          companies={searchContext.appState.filters.companies}
                          marketLabels={searchContext.appState.filters.marketLabels}
                          fetchFilteredData={fetchFilteredData}
                          setIsFilterApply={setIsFilterApply}
                          isFilterApply={isFilterApply}
                      onApplyFilters={(filters) => {
                        setIsFilterApply(true); 
                        fetchProducts(1, filters.activeIngredient, filters.state, null, filters.company, filters.category, filters.marketLabel);
                        setIsFilterModalOpen(false);
                      }}
                          onClearFilters={() => {
                            if(isFilterApply){
                              fetchProducts(1, "", "", null, "", "", "");
                            }
                          }}
                          setSelectedFiltersCount={setSelectedFiltersCount}
                          t={t}
                        i18n={i18n}
                        />
                      )}
                    </div>
                <div className="control-container">
                  <label className="fw-bold pb-1 text-start"><Trans>Sort By</Trans></label>
                  <Select
                    className="basic-multi w-100"
                    classNamePrefix="select-react"
                    isClearable={false}
                    isRtl={searchApplication.isRTL(i18n)}
                    isSearchable={false}
                    placeholder={t("SortBy")}
                    options={sortOrderOptions}
                    value={appState.sortOrder}
                    onChange={sortOrderChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "200px",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "200px", 
                        minWidth: "200px",
                      }),
                      menuList: (provided) => ({
                            ...provided,
                            width: "200px",
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-sm-12">
              <div className="row is-flex">
                {result.map((item) => {
                  const isGoldStar =
                    (typeof item.product.goldStar === "string"
                      ? item.product.goldStar.toLowerCase() !== "false"
                      : Boolean(item.product.goldStar));
                  return isGoldStar ? (
                    <RenderProductInfo
                      key={item.product.id}
                      item={item}
                      appState={appState}
                      settings={settings}
                      searchData={searchData}
                    />
                  ) : null
                })}
                {result.map((item) => {
                  const isGoldStar =
                    (typeof item.product.goldStar === "string"
                      ? item.product.goldStar.toLowerCase() !== "true"
                      : Boolean(!item.product.goldStar));

                  const isSponsor = (item.product.isSponsor ?? "no").toString().toLowerCase() === "yes";

                  return isGoldStar && isSponsor ? (
                    <RenderProductInfo
                      key={item.product.id}
                      item={item}
                      appState={appState}
                      settings={settings}
                      searchData={searchData}
                    />
                  ) : null
                })}
                {result.map((item) => {
                  const isGoldStar =
                    (typeof item.product.goldStar === "string"
                      ? item.product.goldStar.toLowerCase() !== "true"
                      : Boolean(!item.product.goldStar));
                  const isSponsor = (item.product.isSponsor ?? "no").toString().toLowerCase() === "no";
                  return isGoldStar && isSponsor ? (
                    <RenderProductInfo
                      key={item.product.id}
                      item={item}
                      appState={appState}
                      settings={settings}
                      searchData={searchData}
                    />
                  ) : null
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

/**
 * Component to render product information
 * @param {*} param0
 * @returns
 */
function RenderProductInfo({ item, appState, settings, searchData }) {
  //Create a list with new property display name
  let activeIngredients = (item.product.activeIngredient ?? []).map((i) => {
    return { ...i, displayName: searchApplication.getName(i) };
  });
  const urlLogo = item.product?.endorsementDetails?.endorsementData?.map((el, i) => el.logoUrl);

  //Sort the list
  activeIngredients = activeIngredients.sort(
    searchApplication.sortOnAsc("displayName")
  );

  let calculatePadding = (item) => {
    let count = 0;
    if (item.green) count += item.green.length;
    if (item.amber) count += item.amber.length;

    return 100 * count + "px";
  };

  const createProductURL = function (item) {
    const urlParams = new URLSearchParams(window.location.search);
    const stateCode = urlParams.get("state");
    const name = item.product.nameEN;
    const slug = searchApplication.createURLComponentSlug(name);
    const qlng =
      (settings.standalone || appState.showLng) && appState.language
        ? `&lng=${appState.language.value}`
        : "";
    const state = stateCode ? `&state=${stateCode}` : "";
    return `${settings.productdetails_url}/${slug}-${item.product.id}/?country=${appState.country}${qlng}${state}`;
  };

  const handleClick = (e) => {
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
  };
  return (
    <div className="col-xs-1 col-md-6 col-lg-4 d-flex">
      <Link
        to={{
          pathname: createProductURL(item),
          state: { searchData: searchData, searchState: appState },
        }}
        style={{ paddingBottom: calculatePadding(item) }}
        className={
          item.product.authorizeStatus
            ? `mt-4 result-card rounded-3 text-decoration-none w-100 position-relative bg-white ${item.product.authorizeStatus.toLowerCase()}`
            : "result-card rounded-3 text-decoration-none w-100 position-relative bg-white"
        }
        data-id={item.product.id}
        onClick={handleClick}
      >
        {(typeof item.product.goldStar === "string"
          ? item.product.goldStar.toLowerCase() === "true"
          : Boolean(item.product.goldStar)) ? (
          <span className="sponsored d-flex d-block p-1 justify-content-center align-items-center">
            <IconStar className="align-middle me-1" />
            <span className="fw-bold align-middle">
              <Trans>PartnerProduct</Trans>
            </span>
          </span>
        ) : (
          <span className="sponsored d-flex d-block p-1 justify-content-center align-items-center bg-secondary">
            <span className="fw-bold align-middle text-dark">
              <Trans>ProductTileBanner</Trans>
            </span>
          </span>
        )}

        <div className="pt-2 ps-4 d-flex align-items-center pe-4">
          <div
            className={
              (typeof item.product.goldStar === "string"
                ? item.product.goldStar.toLowerCase() === "true"
                : Boolean(item.product.goldStar))
                ? "category text-center p-2 me-2"
                : "category text-center p-2 round me-2"
            }
          >
            {item.product.category && item.product.category.length > 0 ? (
              item.product.category.map((categoryItem, index) => (
                <img
                  key={index}
                  alt={categoryItem.name}
                  src={`${settings.category_icons_folder}${searchApplication.createCategoryIconName(
                    categoryItem.nameEN
                  )}`}
                />
              ))
            ) : null}
          </div>
          <h4 className="product-name">{item?.displayName}</h4>
        </div>

        <div className="ps-4 pe-4 pt-2 pb-2 fs-5">

          <label className="category-label fw-bold mb-0">
            {searchApplication.getName(item.product.category)}
          </label>
          <ul className="active-ingredient p-0 pt-1 pb-2 mb-2 ">
            <label>
              <Trans>Activeingredient</Trans>
            </label>

            {activeIngredients.slice(0, 2).map((aingredient, i) => (
              <li className="ms-3" data-id={aingredient.id} key={i}>
                {aingredient.displayName}
              </li>
            ))}

            {activeIngredients.length > 3 && (
              <li className="ms-3 more" data-id="more">
                <Trans>AIMoreText</Trans>
              </li>
            )}
          </ul>
          {Array.isArray(item.company) && item.company.length > 0 && (
            <ul className="company p-0">
              {item.company.map((companyItem, index) => (
                <li key={index}>
                  <RenderRegistrant item={companyItem?.registrant} />
                  <RenderManufacturer item={companyItem?.manufacturer} />
                  <RenderDistributors items={companyItem?.distributors} />
                </li>
              ))}
            </ul>
          )}
        </div>
        {(item.green || item.amber || item.product?.isOMRIProduct) && (
          <div className="standards ps-4 pe-4 pt-3 position-absolute w-100  d-flex justify-content-between align-items-center">
            {item.product?.isOMRIProduct ? (
              <div className="endorsment d-inline-flex  p-0 mb-2 align-items-center me-2">
                <img style={{ width: '75.58px', height: '41.99px', marginRight: '5px', }}
                  src={item.product?.omriLogoUrl}
                  alt={item.product?.endorsementName}
                />
                {urlLogo?.map((url, index) => (
                  <img
                    key={index}
                    style={{ height: '42px', marginRight: '8px', width: '82px' }}
                    src={url}
                    alt={`Logo ${index + 1}`}
                  />
                ))}

              </div>
            ) : null}
            {item.green &&
              item.green.map((s, i) => (
                <div
                  className="green d-inline-flex p-0 mb-2 align-items-center gap-1 position-relative "
                  key={s.standardBasicData.standardId}
                >

                  <img
                    src={s.standardBasicData.standardLogoURL}
                    alt={s.standardBasicData.standardName}
                    style={{ marginRight: '5px' }}
                  />

                  {urlLogo?.map((url, index) => (
                    <img
                      key={index}
                      style={{ height: '42px', marginRight: '8px', width: '82px' }}
                      src={url}
                      alt={`Logo ${index + 1}`}
                    />
                  ))}
                  <span className="fs-5">
                    <Trans></Trans>
                  </span>
                </div>
              ))}
            {item.amber &&
              item.amber.map((s) => (
                <div
                  key={s.standardBasicData.standardId}
                  className="ambar d-inline-flex p-0 mb-2 align-items-center gap-1 position-relative"
                >
                  <img
                    src={s.standardBasicData.standardLogoURL}
                    alt={s.standardBasicData.standardName}
                    style={{ marginRight: '5px' }}
                  />
                  {urlLogo?.map((url, index) => (
                    <img
                      key={index}
                      style={{ height: '42px', marginRight: '8px', width: '82px' }}
                      src={url}
                      alt={`Logo ${index + 1}`}
                    />
                  ))}
                  <IconExrrorExclamatory
                    className="position-absolute"
                    fill={"#cd4400"}
                  />

                  <span className="fs-5 text-dark">
                    <Trans>
                      {(s.standardBasicData.productDetailAmberLogoLabel !== "RiskMitigationRequired" && s.standardBasicData.productDetailAmberLogoLabel !== "PermittedForUse") ? s.standardBasicData.productDetailAmberLogoLabel : ""}
                    </Trans>
                  </span>


                </div>
              ))}
            <button className="btn moreInfo-btn"><Trans>MoreInfoButton</Trans></button>
          </div>
        )}
        {!item.green && !item.amber && urlLogo && urlLogo?.map((url, index) => (
          <img
            key={index}
            style={{ height: '42px', marginRight: '8px', marginLeft: '1.5rem', width: '82px' }}
            src={url}
            alt={`Logo ${index + 1}`}
          />
        ))}
        {!item.green && !item.amber && <div className="pb-3 ps-4  pe-4 pt-3 d-flex justify-content-end"><button className="btn moreInfo-btn"><Trans>More info</Trans></button></div>}

      </Link>

    </div>
  );
}

/**
 * Component to render distributors
 * @param {Array} param0
 * @returns
 */
function RenderDistributors({ items }) {
  return Array.isArray(items) && items.length > 0 ? (
    <li className="distributors pb-2">
      <ul className="p-0">
        <label >
          <Trans>Distributors</Trans> :
        </label>
        {items.map((item) => (
          <li className="d-inline" data-id={item.id} key={item.id}>
            <span className="fs-8"> {searchApplication.getName(item)}</span>
          </li>
        ))}
      </ul>
    </li>
  ) : null;
}

/**
 * Component to render registrant
 * @param {*} param0
 * @returns
 */
function RenderRegistrant({ item }) {
  return item ? (
    <li className="registrant pb-2" data-id={item.id}>
      <span >
        <Trans>Registrant</Trans> {searchApplication.getName(item)}
      </span>
    </li>
  ) : null;
}

/**
 * Component to render manufaturer
 * @param {*} param0
 * @returns
 */
function RenderManufacturer({ item }) {
  return item ? (
    <li className="manufacturer pb-2" data-id={item.id}>
      <span >
        <Trans>Manufacturer</Trans>: {searchApplication.getName(item)}
      </span>
    </li>
  ) : null;
}

function RenderResultCount(settings, start, end, appState, t) {
  let key = "ShowingText";
  let template = t(key);
  if (template === key) {
    template = "Showing {0} - {1} of {2}"; //Translation not available
  }

  let values = [
    start ?? 1,
    settings?.is_pagination ? end : appState.totalCount,
    appState.totalCount,
  ];

  let result = template.replace(/{(\d+)}/g, (_, index) => values[index]);
  return <span className="ms-1">{result}</span>;
}

export default withTranslation()(SearchResult);
