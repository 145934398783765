import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import App from "./App";
import { SettingsProvider } from "./components/settingsContext";
import { searchApplication, consoleLogger } from "./services/utilities";
import { LoadingComponent } from "./components/loading";

const rootEl = document.getElementById("r-a");

/** if root element exists in the page to mount the app */
if (rootEl) {
    let settings = JSON.parse(rootEl.getAttribute("data-default-settings"));

    settings.standalone = settings.standalone ?? false;

    settings.is_mobile = settings.is_mobile ?? false;

    settings.show_offline_prompt =
        settings.offline_app_url && !settings.standalone
            ? settings.show_offline_prompt ?? false
            : false;

    settings.getlng = function () {
        let lng = document.documentElement.lang;
        if (lng === 'en-US') {
            lng = 'en';
        }

        if (!settings.standalone) {
            const urlParams = new URLSearchParams(window.location.search);
            const lngFromUrl = urlParams.get('lng');
            if (lngFromUrl) {
                lng = lngFromUrl;
            }
        }

        return lng;
    }

    //Initialize language and other settings
    settings = searchApplication.init(settings);

    if (settings.standalone) {
        settings.searchpage_url = `${settings.searchpage_url}`;

        settings.productdetails_url = `${settings.productdetails_url}`;
    }
    else {
        let doclng = searchApplication.getDocLng();

        settings.searchpage_url = doclng === settings.default_lang ? `${settings.searchpage_url}` : `/${doclng}${settings.searchpage_url}`;

        settings.productdetails_url = doclng === settings.default_lang ? `${settings.productdetails_url}` : `/${doclng}${settings.productdetails_url}`;
    }

    window.settings = settings;

    //Enable or Disable console log
    consoleLogger.enabled = settings.console_log ?? true;

    ReactDOM.render(
        <React.StrictMode>
            <React.Suspense fallback={<LoadingComponent />}>
                <I18nextProvider i18n={i18n}>
                    <SettingsProvider value={settings}>
                        <App />
                    </SettingsProvider>
                </I18nextProvider>
            </React.Suspense>
        </React.StrictMode>,
        rootEl
    );
}
