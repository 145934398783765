import React, { useState, useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { SearchForm } from "../searchForm";
import { SearchResult } from "../searchResult";

import { SettingsContext } from "../settingsContext";
import { SearchProvider } from "../searchContext";
import { LoadingComponent } from "../loading";
import { ErrorUI } from "../errorUI";
import i18n from "../../i18n";

import { dataServices } from "../../services/dataServices";
import { searchApplication, consoleLogger } from "../../services/utilities";
import { IconArrowLeft } from "../icons";
import { Pagination } from "../shared/Pagination";

import "../../scss/Search.scss";

/**
 * Component to render search UI
 * @param {*} props
 * @returns render search UI
 */
function Search(props) {
    const { t } = props;
    const settings = React.useContext(SettingsContext);

    //Get query param from Url
    const queryParam = searchApplication.historyHelper.queryParam(
        useLocation().search
    );
      
    const history = useHistory();

    let details = navigator.userAgent; 

let regexp = /android|iphone/i; 

let isMobileDevice = regexp.test(details); 

    //Result sort order list
    const sortOrderOptions = [
        { value: "ASC", label: t("Product(A-Z)") },
        { value: "DESC", label: t("Product(Z-A)") },
    ];

    //State for handling errors
    const [errorState, setErrorState] = useState(false);

    //Get language from the attribute
    const language = settings.getlng();

    //Create the search page state
    const [appState, setAppState] = useState({
        country: queryParam.country,
        countryName: null,

        language: language,
        occupations: [],

        countries: [],
        languages: [],

        crops: [],
        pests: [],
        activeIngredients: [],
        supportedStates: [],

        occupation: null,
        crop: null,
        pest: null,
        result: null,
        activeIngredient: null,
        supportedState: null,

        currentPage: 1,
        pageSize: (window.innerWidth > 768) ? settings?.page_size_for_desktop: settings?.page_size_for_mobile,
        totalCount: null,

        sortOrder: queryParam.sortOrder
            ? queryParam.sortOrder.toLowerCase() === "desc"
                ? sortOrderOptions[1]
                : sortOrderOptions[0]
            : null,

        disabledCrop: true,
        disabledPest: true,
        disabledOccupation: true,

        showError: false,
        filter: queryParam.filter,
        selectedFilters: {
            state: [],
            category: [],
            activeIngredient: [],
            company: [],
            marketLabel: [],
          }
    });

    const [isFilterApply, setIsFilterApply] = useState(false);
    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
    /**
     * Method when a search is perfomed
     * @param {object} resultFromSearch     
     * @param {object} newState
     */
    const searchPerformed = (
        resultFromSearch,
        newState = null,
        activeIngredientId = null,
        sortOrder = null,
        selectedStates = null,
        currentPage = 1,
        count = 0
    ) => {
        var searchState = props?.location?.state;
        currentPage = Number(currentPage);
        var searchResult = resultFromSearch?.productsList ?? resultFromSearch;
        var totalCount = resultFromSearch?.count ?? newState?.totalCount ?? resultFromSearch?.length;

        if(count > 0) totalCount=count; 

        searchResult?.forEach((item) => {
            item.displayName = searchApplication.getName(item.product);
        });

        if (searchState && sortOrder === null) {
            let state = null;
            history.replace({ ...history.location, state });
        } 

        let activeIngredients = resultFromSearch?.activeIngredients ?? appState?.activeIngredients
        let filters = resultFromSearch?.filters ?? appState?.filters

        // get active selected ingredient ID incase activeIngredientId contains active ingredient name
        const matchedIngredient = activeIngredients.find(a => a.label === activeIngredientId || a.value === activeIngredientId);
        activeIngredientId = matchedIngredient ? matchedIngredient.value : activeIngredientId;
        
        // Get sorted supported states list from the result
        var supportedStates = searchApplication.supportedStates(
            searchResult,
            activeIngredientId
        );

        //if newState is undefined assign the appState
        newState = newState ?? appState;

        //set active-ingredient
        var activeIngredient = null;
        if (activeIngredients?.length > 0 && activeIngredientId) {
            var selected = activeIngredients.filter(
                (item) => item.value === activeIngredientId
            );

            if (selected.length === 1) {
                activeIngredient = {
                    label: selected[0].label,
                    label_en: selected[0].label_en,
                    value: selected[0].value,
                };
            }
        }

        //set supported-state
        var mySelectedState = [];
        if (supportedStates?.length > 0 && (selectedStates && selectedStates?.length > 0)) {
            selectedStates.split(",").forEach(function (state) {
                selected = supportedStates.filter((item) => item.value === state);

                if (selected.length === 1) {
                    mySelectedState.push({
                        value: selected[0].value,
                        label: selected[0].label,
                    });
                }
            });
        }

        const newestState = {
            ...newState,
            totalCount: totalCount,
            result: searchResult,
            filters: filters,
            activeIngredients: activeIngredients,
            activeIngredient: activeIngredient,
            supportedStates: supportedStates,
            supportedState: mySelectedState,
            sortOrder: sortOrder,
            currentPage: currentPage,
            showError: false,
        }

        //Set the new state
        setAppState(newestState);
        
        searchApplication.historyHelper.updateHistory(history, {
            country: newestState?.country ?? "",
            lng: newestState?.language ? newestState.language.value : null,
            occupation: newestState.occupation?.value ?? "",
            crop: newestState?.crop?.value ?? "",
            pest: newestState?.pest?.value ?? "",
            filter: newestState?.filters ?? "",
            activeIngredient: activeIngredient?.label ?? "",
            sortOrder: sortOrder?.value?.toLowerCase() ?? "",
            standalone: settings.standalone ?? "",
            pageNo: (newestState.result?.length > 0) && newestState?.currentPage,
            supportedStates: selectedStates
        });
    };

    const handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            setTimeout(function () {
                window.scrollTo(0, parseInt(scrollPosition));
                sessionStorage.removeItem("scrollPosition");
            }, 100);
        }
    };

    useEffect(() => {

        var searchState = props?.location?.state;

        //Check wheather returns from product details page
        //If get state, then render the search page with that state
        if (searchState) {
            var newState = searchState;
            const result = {
                activeIngredients: newState?.activeIngredients ?? [],
                filters: newState?.filters ?? [],
                productsList: newState?.result ?? [],
            }
            searchPerformed(
                result,
                newState,
                newState.activeIngredient ? newState.activeIngredient.value : null,
                newState.sortOrder
                    ? newState.sortOrder && newState.sortOrder.value.toLowerCase() === "desc"
                        ? sortOrderOptions[1]
                        : sortOrderOptions[0]
                    : null,
                newState.supportedState
                    ? newState.supportedState
                        .map(function (item) {
                            return item.value;
                        })
                        .join(",")
                    : null,
                newState.currentPage
            );

            handleScrollPosition();

        } else if (queryParam.country) {

            // Hardcoded filter object
            const filterObject = {
                activeIngredients: queryParam?.activeIngredient ?? "",
                states: queryParam?.supportedStates?.split(",") ?? []
            };
 
                sessionStorage.setItem("filtersSelected", JSON.stringify({
                  state: [],
                  activeIngredient: [],
                  category: [],
                  company: [],
                  marketLabel: []
                }));
              
            dataServices
                .getInitialData(
                    queryParam.country,
                    language,
                    queryParam.crop,
                    queryParam.pest,
                    queryParam.filter,
                    settings,
                    queryParam.state,
                    appState?.pageSize,
                    queryParam.pageNo ?? 1,
                    JSON.stringify(filterObject), 
                    queryParam.sortOrder, 
                    "product"
                )
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;

                        if (data.countryName === "" || data.occupations.length === 0) {
                            setErrorState(true);
                            return;
                        } else {
                            setErrorState(false);
                        }

                        //create new state
                        var newState = searchApplication.transformInitialData(
                            appState,
                            language,
                            queryParam.occupation,
                            queryParam.crop,
                            queryParam.pest,
                            data,
                            t,
                            queryParam.pageNo ?? 1
                        );

                        searchPerformed(
                            newState.result,
                            newState,
                            queryParam.activeIngredient,
                            queryParam.sortOrder
                                ? queryParam.sortOrder.toLowerCase() === "desc"
                                    ? sortOrderOptions[1]
                                    : sortOrderOptions[0]
                                : null,
                            queryParam.supportedStates,
                            queryParam?.pageNo ?? 1,
                            data?.count
                        );

                        if (
                            newState.countryName &&
                            newState.language
                        ) {

                            let lang = newState.languages.find(s=> s.value == newState.language.value);

                            //Push Analytic
                            searchApplication.pushSearchAnalytic(
                                queryParam.country,
                                newState.countryName,
                                lang ? lang.nameEn : null,
                                newState.language ? newState.language.value : null,
                                newState.occupation !== null ? newState.occupation.label : "",
                                newState.occupation !== null
                                    ? newState.occupation.label_en
                                    : "",
                                newState.crop !== null ? newState.crop.value : "",
                                newState.crop !== null ? newState.crop.label : "",
                                newState.crop !== null ? newState.crop.label_en : "",
                                newState.pest !== null ? newState.pest.value : "",
                                newState.pest !== null ? newState.pest.label : "",
                                newState.pest !== null ? newState.pest.label_en : "",
                                data.products ? data.products.length : 0
                            );
                        }
                    } else {
                        consoleLogger(["Search:useEffect:getInitialData", response]);
                        setErrorState(true);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["Search:useEffect:getInitialData", error]);
                    setErrorState(true);
                });
        } else {
            newState = { ...appState, country: "", countryName: "Select" };
            searchPerformed(
                null,
                newState,
                queryParam.activeIngredient,
                queryParam.sortOrder
                    ? queryParam.sortOrder.toLowerCase() === "desc"
                        ? sortOrderOptions[1]
                        : sortOrderOptions[0]
                    : null,
                queryParam.supportedStates,
                queryParam?.pageNo
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Scroll to top on load
    useEffect(() => {
        //window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
  * Event listner when page number button clicked
  * @param {*} e
  */
    const fetchProducts = (pageNumber, activeIngredient = [], states = [], sortOrder = null, company = [], category = [], marketLabel = []) => {        
        const urlParams = new URLSearchParams(window.location.search);
        let stateParam = urlParams.get('state');
        if (!stateParam) stateParam = urlParams.get('supportedState');

        const crop = appState.crop?.value ?? '';
        const pest = appState.pest?.value ?? '';
        const currentSelectedFilters = {
            activeIngredient: [],
            state: [],
            company: [],
            category: [],
            marketLabel: []
        };

        const storedFilters = sessionStorage.getItem("filtersSelected");
        if (storedFilters) {
          try {
            const filtersObject = JSON.parse(storedFilters);
            console.log("Deserialized filters:", filtersObject);
    
            if (filtersObject.state?.length > 0) {
                currentSelectedFilters.state = filtersObject.state;
            }
            if (filtersObject.category?.length > 0) {
                currentSelectedFilters.category = filtersObject.category;
            }
            if (filtersObject.activeIngredient?.length > 0) {
                currentSelectedFilters.activeIngredient = filtersObject.activeIngredient;
            }
            if (filtersObject.company?.length > 0) {
                currentSelectedFilters.company = filtersObject.company;
            }
            if (filtersObject.marketLabel?.length > 0) {
                currentSelectedFilters.marketLabel = filtersObject.marketLabel;
            }
          } catch (error) {
            console.error("Failed to parse filtersSelected from sessionStorage while fetchProduct:", error);
          }
        }

        var supportedStates = "";

        const ai  = activeIngredient?.length > 0 ? activeIngredient : currentSelectedFilters.activeIngredient;
        const st  = states?.length > 0 ? states : currentSelectedFilters.state;
        const comp = company?.length > 0 ? company : currentSelectedFilters.company;
        const cat = category?.length > 0 ? category : currentSelectedFilters.category;
        const ml  = marketLabel?.length > 0 ? marketLabel : currentSelectedFilters.marketLabel;
        

        if(appState.countryName ==="United States"){
            supportedStates = st?.length > 0 ? st.map(s => s.value) : [];
        }
        const supportedIngredient = ai?.length > 0 ? ai.map(s => s.value) : [];
        const supportedCompany = comp?.length > 0 ? comp.map(s => s.value) : [];
        const supportedCategory = cat?.length > 0 ? cat.map(s => s.value) : [];
        const supportedMarketLabel = ml?.length > 0 ? ml.map(s => s.value) : [];          

        // Hardcoded filter object
        const filterObject = {
            activeIngredients: supportedIngredient,
            states: supportedStates,
            company: supportedCompany,
            category: supportedCategory,
            marketLabel: supportedMarketLabel
        };
        const filterString = JSON.stringify(filterObject);
        dataServices
            .getProducts(appState.country, settings.getlng(), crop, pest, "", appState.pageSize, pageNumber, settings, filterString, sortOrder?.value?.toLowerCase(), "product")
            .then((response) => {
                if (response.status === 200) {

                    let lang = appState.languages.find(s => s.value == appState.language.value);

                    searchApplication.pushSearchAnalytic(
                        appState.country,
                        appState.countryName,
                        lang ? lang.nameEn : null,
                        appState.language ? appState.language.value : null,
                        appState.occupation !== null ? appState.occupation.label : "",
                        appState.occupation !== null
                            ? appState.occupation.label_en
                            : "",
                        appState.crop !== null ? appState.crop.value : "",
                        appState.crop !== null ? appState.crop.label : "",
                        appState.crop !== null ? appState.crop.label_en : "",
                        appState.pest !== null ? appState.pest.value : "",
                        appState.pest !== null ? appState.pest.label : "",
                        appState.pest !== null ? appState.pest.label_en : "",
                        response.data ? response.data.length : null
                    );
                    searchPerformed(response.data, null, supportedIngredient ,sortOrder, (supportedStates.length > 0) ? supportedStates?.join(",") : null, pageNumber);
                    window.scrollTo(0, 0);
                } else {
                    consoleLogger(["SearchForm:getProducts", response]);
                }
            })
            .catch(function (error) {
                consoleLogger(["SearchForm:getProducts", error]);
            });
    }

    if (appState.countryName && !errorState) {
        return (
            <SearchProvider value={{ appState, setAppState }}>
                <div
                    className={`s-p ${searchApplication.replaceSpecialCharacter(
                        appState.countryName.toLowerCase()
                    )}`}
                >
                    {!settings.standalone && !isMobileDevice?
                    <section>
                        <div className= "container pt-3 pb-3">
                            <div className="row">
                                <div className="col-12">
                                    <a
                                        href={
                                            searchApplication.getDocLng() === settings.default_lang || settings.standalone
                                                ? "/"
                                                : `/${searchApplication.getDocLng()}`
                                        }
                                        className="btn-tertiary text-decoration-none align-middle"
                                    >
                                        {i18n.dir() === 'ltr' ? <IconArrowLeft className="me-1 align-middle" /> : null}
                                        <Trans>BackToHome</Trans>
                                        {i18n.dir() === 'rtl' ? <IconArrowLeft className="me-1 align-middle" /> : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section> : null}
                    <SearchForm
                        sortOrderOptions={sortOrderOptions}
                        searchPerformed={searchPerformed}
                        setSelectedFiltersCount ={setSelectedFiltersCount}
                    />
                    <SearchResult
                        sortOrderOptions={sortOrderOptions}
                        fetchProducts={fetchProducts}
                        isFilterApply={isFilterApply}
                        setIsFilterApply={setIsFilterApply}
                        selectedFiltersCount ={selectedFiltersCount}
                        setSelectedFiltersCount ={setSelectedFiltersCount}
                    />
                    {appState.result?.length > 0 && settings?.is_pagination && <Pagination fetchProducts={fetchProducts} />}
                </div>
            </SearchProvider>
        );
    } else {
        return errorState ? <ErrorUI /> : <LoadingComponent />;
    }
}

export default withTranslation()(Search);
