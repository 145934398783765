import React, { useState,useEffect, useMemo, useRef  } from "react";
import Select, { components } from "react-select";
import {Trans } from "react-i18next";
import { dataServices } from "../../services/dataServices";
import { SearchContext } from "../searchContext"; 
import { SettingsContext } from "../settingsContext";


const FiltersModal = ({
  isOpen,
  setIsOpen,
  countryName,
  activeIngredients: initialActiveIngredients,
  states: initialStates,
  categories: initialCategories,
  companies: initialCompanies,
  marketLabels: initialMarketLabels,
  fetchFilteredData,
  onApplyFilters,
  onClearFilters,
  setSelectedFiltersCount,
  t,
  i18n,
 setIsFilterApply,
 isFilterApply
}) => {
  const [states, setStates] = useState(initialStates);
  const [categories, setCategories] = useState(initialCategories);
  const [activeIngredients, setActiveIngredients] = useState(initialActiveIngredients);
  const [companies, setCompanies] = useState(initialCompanies);
  const [marketLabels, setMarketLabels] = useState(initialMarketLabels);

  const [statesSelect, setStatesSelect] = useState([]);
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [activeIngredientsSelect, setActiveIngredientsSelect] = useState([]);
  const [companiesSelect, setCompaniesSelect] = useState([]);
  const [marketLabelsSelect, setMarketLabelsSelect] = useState([]);

  useEffect(() => {
    if (!isOpen) return;

    const storedFilters = sessionStorage.getItem("filtersSelected");
    if (storedFilters) {
      try {
        const filtersObject = JSON.parse(storedFilters);
        console.log("Deserialized filters:", filtersObject);

        if (filtersObject.state?.length > 0) {
          setStatesSelect(filtersObject.state);
        }
        if (filtersObject.category?.length > 0) {
          setCategoriesSelect(filtersObject.category);
        }
        if (filtersObject.activeIngredient?.length > 0) {
          setActiveIngredientsSelect(filtersObject.activeIngredient);
        }
        if (filtersObject.company?.length > 0) {
          setCompaniesSelect(filtersObject.company);
        }
        if (filtersObject.marketLabel?.length > 0) {
          setMarketLabelsSelect(filtersObject.marketLabel);
        }
      } catch (error) {
        console.error("Failed to parse filtersSelected from sessionStorage in FilterModel:", error);
      }
    }
  }, [isOpen]);


  const sortedStates = useMemo(() => states?.map((state) => ({
    label: state.stateName,
    value: state.stateCode,
  })).sort((a, b) => a.label.localeCompare(b.label)), [states]);

  const sortedProductCategories = useMemo(() => categories?.map((category) => ({
    label: category.nameEn,
    value: category.id,
  })).sort((a, b) => a.label.localeCompare(b.label)), [categories]);

  const sortedActiveIngredients = useMemo(() => activeIngredients?.flat().map((ingredient) => ({
    label: ingredient.nameEn,
    value: ingredient.id,
  })).sort((a, b) => a.label.localeCompare(b.label)), [activeIngredients]);

  const sortedCompanies = useMemo(() => companies?.flat().map((company) => ({
    label: company.nameEn,
    value: company.id,
  })).sort((a, b) => a.label.localeCompare(b.label)), [companies]);

  const sortedMarketLabels = useMemo(() => marketLabels?.flat().map((label) => ({
    label: label.nameEn,
    value: label.id,
  })).sort((a, b) => a.label.localeCompare(b.label)), [marketLabels]);


  useEffect(() => {
    const count =
      (statesSelect.length > 0 ? 1 : 0) +
      (categoriesSelect.length > 0 ? 1 : 0) +
      (activeIngredientsSelect.length > 0 ? 1 : 0) +
      (companiesSelect.length > 0 ? 1 : 0) +
      (marketLabelsSelect.length > 0 ? 1 : 0);

    setSelectedFiltersCount(count);
    setIsApplyDisabled(count === 0);
  }, [
    statesSelect,
    categoriesSelect,
    activeIngredientsSelect,
    companiesSelect,
    marketLabelsSelect,
  ]);

  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [dropdownState, setDropdownState] = useState({
    state: false,
    activeIngredient: false,
    category: false,
    company: false,
    marketLabel: false,
  });

  const searchContext = React.useContext(SearchContext);
  const appState = searchContext.appState;
  const setAppState = searchContext.setAppState;
  const dropdownOpenRef = useRef(false);
  const toggleDropdown = (field, isOpen) => {
    dropdownOpenRef.current = isOpen;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? "^" : "v"}
      </components.DropdownIndicator>
    );
  };

  const handleFilterChange = async (field, value) => {
    let updatedFilters = {
      state: statesSelect,
      category: categoriesSelect,
      activeIngredient: activeIngredientsSelect,
      company: companiesSelect,
      marketLabel: marketLabelsSelect,
    };
  
    // Handle cascading logic
    if (!value) {
      if (field === "state") {
        updatedFilters = { state: [], category: [], activeIngredient: [], company: [], marketLabel: [] };
      } else if (field === "category") {
        updatedFilters = { ...updatedFilters, category: [], activeIngredient: [], company: [], marketLabel: [] };
      } else if (field === "activeIngredient") {
        updatedFilters = { ...updatedFilters, activeIngredient: [], company: [], marketLabel: [] };
      } else if (field === "company") {
        updatedFilters = { ...updatedFilters, company: [], marketLabel: [] };
      } else {
        updatedFilters[field] = [];
      }
    } else {
      const currentValues = updatedFilters[field] || [];
      const isAlreadySelected = currentValues.some((item) => item.value === value.value);
  
      // Clear lower filters if a higher one changes
      if (field === "state") updatedFilters = { ...updatedFilters, category: [], activeIngredient: [], company: [], marketLabel: [] };
      if (field === "category") updatedFilters = { ...updatedFilters, activeIngredient: [], company: [], marketLabel: [] };
      if (field === "activeIngredient") updatedFilters = { ...updatedFilters, company: [], marketLabel: [] };
      if (field === "company") updatedFilters = { ...updatedFilters, marketLabel: [] };
  
      // Toggle selection
      updatedFilters[field] = isAlreadySelected
        ? currentValues.filter((item) => item.value !== value.value)
        : [...currentValues, value];
    }
  
    // Update dropdown UI state
    setStatesSelect(updatedFilters.state);
    setCategoriesSelect(updatedFilters.category);
    setActiveIngredientsSelect(updatedFilters.activeIngredient);
    setCompaniesSelect(updatedFilters.company);
    setMarketLabelsSelect(updatedFilters.marketLabel);
  
    // Fetch dependent filter data
    const data = await fetchFilteredData(updatedFilters);
    if (data) {
      if (field !== "state" || updatedFilters.state.length === 0) setStates(data.states || []);
      if (field !== "category" || updatedFilters.category.length === 0) setCategories(data.categories || []);
      if (field !== "activeIngredient" || updatedFilters.activeIngredient.length === 0) setActiveIngredients(data.activeIngredients || []);
      if (field !== "company" || updatedFilters.company.length === 0) setCompanies(data.companies || []);
      if (field !== "marketLabel" || updatedFilters.marketLabel.length === 0) setMarketLabels(data.marketLabels || []);
    }
  };
  

  const applyFilters = () => {
    const currentSelectedFilters = {
      state: statesSelect,
      category: categoriesSelect,
      activeIngredient: activeIngredientsSelect,
      company: companiesSelect,
      marketLabel: marketLabelsSelect,
    };

    sessionStorage.setItem("filtersSelected", JSON.stringify(currentSelectedFilters));
    onApplyFilters(currentSelectedFilters);
    setIsOpen(false);
  };
  const clearFilters = async () => {

    setIsFilterApply(false);
    const emptyFilters = {
      state: [],
      category: [],
      activeIngredient: [],
      company: [],
      marketLabel: []
    };
    const data = await fetchFilteredData(emptyFilters);
    if (data) {
      setStates(data.states || []);
      setCategories(data.categories || []);
      setActiveIngredients(data.activeIngredients || []);
      setCompanies(data.companies || []);
      setMarketLabels(data.marketLabels || []);
    } else {
      setStates([]);
      setCategories([]);
      setActiveIngredients([]);
      setCompanies([]);
      setMarketLabels([]);
    }
    setStatesSelect([]);
    setCategoriesSelect([]);
    setActiveIngredientsSelect([]);
    setCompaniesSelect([]);
    setMarketLabelsSelect([]);
    setSelectedFiltersCount(0);
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: "relative",
      zIndex: 1050,
    }),
    control: (provided) => ({
      ...provided,
      borderColor: "black",
      boxShadow: "0 0 0 0.1px black",
      "&:hover": {
        borderColor: "black", 
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px", // Limit dropdown height
      overflowY: "auto", // Allow scrolling
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      backgroundColor: isSelected ? "#2E7D32" : isFocused ? "#C8E6C9" : "white", 
      color: isSelected ? "white" : "#2E7D32", 
      fontWeight: "bold",
      ":hover": {
        backgroundColor: "#C8E6C9",
        color: "#2E7D32",
      },
    }),
  };
  const CustomSingleValue = () => null;
  const CustomMultiValue = () => null;
  
  const CustomValueContainer = (props) => {
    const { children, selectProps } = props;
    
    return (
      <components.ValueContainer {...props}>
        {!selectProps.inputValue && (
          <div style={{ color: "#999", position: "absolute", left: "10px" }}>
            {selectProps.placeholder}
          </div>
        )}
        {children}
      </components.ValueContainer>
    );
  };
  if (!isOpen) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "100%", // Full width on mobile
          //height: "100%", // Full height on mobile
          maxWidth: "700px", // Limit width on larger screens
          maxHeight: "90vh", // Limit height on larger screens
          overflow: "auto",
          position: "relative",
        }}
      >
        <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}>
        <Trans>FilterBy</Trans>
        </h2>
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            const stored = sessionStorage.getItem("filtersSelected");
            let count = 0;
            if (stored) {
              var filters = JSON.parse(stored);
              count = (filters.state?.length > 0 ? 1 : 0) +
                (filters.activeIngredient?.length > 0 ? 1 : 0) +
                (filters.category?.length > 0 ? 1 : 0) +
                (filters.company?.length > 0 ? 1 : 0) +
                (filters.marketLabel?.length > 0 ? 1 : 0)
            }
            setSelectedFiltersCount(count);
            setIsOpen(false);
          }}
        >
          ✖
        </button>
        <div style={{ display: "flex", flexDirection: "column", height:"auto",overflow: "auto", gap: "10px" }}>
        {countryName === "United States" && (
            <>
              <label style={{ fontWeight: "bold" }}><Trans>States</Trans></label>
              <Select
                  options={sortedStates?.map((state) => ({
                    label: state.label, // Display name
                    value: state.value,        // Use id as the value (or use stateName if needed)
                  }))}
                isClearable={false}
                isSearchable={true}
                classNamePrefix="custom-select"
                styles={customStyles}
                value={statesSelect}
                onChange={(value) => handleFilterChange("state", value)}
                onMenuOpen={() => toggleDropdown("state", true)}
                onMenuClose={() => toggleDropdown("state", false)}
                placeholder={t("Select")} 
                components={{
                  DropdownIndicator,
                  SingleValue: CustomSingleValue,
                  MultiValue: CustomMultiValue,
                  ValueContainer: CustomValueContainer,
                }}
                noOptionsMessage={() => <Trans>Nooptions</Trans>}
              />
              <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
            {statesSelect?.map((item) => (
              <span
                key={item.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid green",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                {item.label}
                <button type="button"
                  onClick={() => handleFilterChange("state", item)}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "green",
                  }}
                >
                  ✖
                </button>
              </span>
            ))}
          </div>
            </>
          )}
          <label style={{ fontWeight: "bold" }}>
          <Trans>ProductCategoryFilter</Trans>
          </label>
          <Select
            options={sortedProductCategories?.map((category) => ({
              label: category.label,
              value: category.value, 
            }))}
            isClearable={false}
            isSearchable={true}
            classNamePrefix="custom-select"
            styles={customStyles}
            value={categoriesSelect}
            onChange={(value) => handleFilterChange("category", value)}
            onMenuOpen={() => toggleDropdown("category", true)}
            onMenuClose={() => toggleDropdown("category", false)}
            placeholder={t("Select")} 
            components={{
              DropdownIndicator,
              SingleValue: CustomSingleValue,
              MultiValue: CustomMultiValue,
              ValueContainer: CustomValueContainer,
            }}
            noOptionsMessage={() => <Trans>Nooptions</Trans>}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
            {categoriesSelect?.map((item) => (
              <span
                key={item.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid green",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                {item.label}
                <button type="button"
                  onClick={() => handleFilterChange("category", item)}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "green",
                  }}
                >
                  ✖
                </button>
              </span>
            ))}
          </div>
          <label style={{ fontWeight: "bold" }}>
          <Trans>Activeingredient</Trans>
          </label>
          <Select
            options={sortedActiveIngredients.flat().map((ingredient) => ({
              label: ingredient.label,
              value: ingredient.value,
            }))}
            isClearable={false}
            isSearchable={true}
            classNamePrefix="custom-select"
            styles={customStyles}
            value={activeIngredientsSelect}
            onChange={(value) => handleFilterChange("activeIngredient", value)}
            onMenuOpen={() => toggleDropdown("activeIngredient", true)}
            onMenuClose={() => toggleDropdown("activeIngredient", false)}
            placeholder={t("Select")} 
            components={{
              DropdownIndicator,
              SingleValue: CustomSingleValue,
              MultiValue: CustomMultiValue,
              ValueContainer: CustomValueContainer,
            }}
            noOptionsMessage={() => <Trans>Nooptions</Trans>}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
            {activeIngredientsSelect?.map((item) => (
              <span
                key={item.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid green",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                {item.label}
                <button type="button"
                  onClick={() => handleFilterChange("activeIngredient", item)}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "green",
                  }}
                >
                  ✖
                </button>
              </span>
            ))}
          </div>
          <label style={{ fontWeight: "bold" }}>
          <Trans>CompanyFilter</Trans>
          </label>
          <Select
            options={sortedCompanies?.flat().map((company) => ({
              label: company.label,
              value: company.value,
            }))}
            isClearable={false}
            isSearchable={true}
            classNamePrefix="custom-select"
            styles={customStyles}
            value={companiesSelect}
            onChange={(value) => handleFilterChange("company", value)}
            onMenuOpen={() => toggleDropdown("company", true)}
            onMenuClose={() => toggleDropdown("company", false)}
            placeholder={t("Select")} 
            components={{
              DropdownIndicator,
              SingleValue: CustomSingleValue,
              MultiValue: CustomMultiValue,
              ValueContainer: CustomValueContainer,
            }}
            noOptionsMessage={() => <Trans>Nooptions</Trans>}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
            {companiesSelect?.map((item) => (
              <span
                key={item.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid green",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                {item.label}
                <button type="button"
                  onClick={() => handleFilterChange("company", item)}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "green",
                  }}
                >
                  ✖
                </button>
              </span>
            ))}
          </div>
          <label style={{ fontWeight: "bold" }}>
          <Trans>MarketlabelFilter</Trans>
          </label>
          <Select
            options={sortedMarketLabels?.flat().map((lable) => ({
              label: lable.label,
              value: lable.value,
            }))}
            isClearable={false}
            isSearchable={true}
            classNamePrefix="custom-select"
            styles={customStyles}
            value={marketLabelsSelect}
            onChange={(value) => handleFilterChange("marketLabel", value)}
            onMenuOpen={() => toggleDropdown("marketLabel", true)}
            onMenuClose={() => toggleDropdown("marketLabel", false)}
            placeholder={t("Select")} 
            components={{
              DropdownIndicator,
              SingleValue: CustomSingleValue,
              MultiValue: CustomMultiValue,
              ValueContainer: CustomValueContainer,
            }}
            noOptionsMessage={() => <Trans>Nooptions</Trans>}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px", marginTop: "8px" }}>
            {marketLabelsSelect?.map((item) => (
              <span
                key={item.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid green",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  color: "green",
                  fontWeight: "bold",
                }}
              >
                {item.label}
                <button type="button"
                  onClick={() => handleFilterChange("marketLabel", item)}
                  style={{
                    marginLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "green",
                  }}
                >
                  ✖
                </button>
              </span>
            ))}
          </div>        
        <br></br>
        
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            marginTop: "10px",
          }}>
          <button
            type="button"
            style={{
              color: "#2E7D32",
              fontWeight: "bold",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
            onClick={clearFilters}
            disabled={isApplyDisabled}
          ><Trans>ClearAllFilters</Trans>
          </button>
          <button
            style={{
              backgroundColor: "#2E7D32",
              color: "white",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={applyFilters}>
            <Trans>FilterResultsButton</Trans>
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;